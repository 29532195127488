import React, { useEffect, useState } from 'react';
import './Footer.css';

const Footer = () => {

    const [year, setYear] = useState();

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);

    }, [])
    return (
        <div className="Footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-12 contact-me d-flex justify-content-md-center my-3">
                        <div>
                            <h5>Contact Me</h5>
                            <p><i className="fas fa-phone-alt me-2"></i><span>Phone:</span>+88 01940-909766</p>
                            <p><i className="fas fa-envelope me-2"></i><span>Email:</span>musaberulkhan@gmail.com</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 d-flex justify-content-md-center my-3">
                        <div>
                            <h5>Social Links</h5>
                            <div className="social-links">
                                <span><a href="https://www.facebook.com/seyam.khan.121/" alt="" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a></span>
                                <span><a href="wechat.html" alt="" target="_blank" rel="noopener noreferrer"><i className="fab fa-weixin"></i></a></span>
                                <span><a href="https://www.instagram.com/musaberulkhan/" alt="" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></span>
                                <span><a href="whatsapp.html" alt="" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 d-flex justify-content-md-center my-3">
                        <div className="github-linkedin">
                            <a href="https://github.com/musaberulkhan/" target="_blank" rel="noopener noreferrer">
                                <div>
                                    <i className="fab fa-github"></i>
                                    <h4 className="d-inline-block">GitHub</h4>
                                </div>
                            </a>
                            <a href="https://www.linkedin.com/in/musaberul/" target="_blank" rel="noopener noreferrer">
                                <div>
                                    <i className="fab fa-linkedin"></i>
                                    <h4 className="d-inline-block">LinkedIn</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="copyright">
                    <p>Concept and Designed by <span>Ahmed Musaberul Khan</span> | @{year}</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
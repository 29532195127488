import React, { useEffect, useState } from 'react';
import Course from './Course/Course';
import './Courses.css';

const Courses = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        fetch('courses.json')
            .then(res => res.json())
            .then(data => setCourses(data));
    }, []);

    return (
        <div>
            <div className="container-fluid pt-3 pb-5 accomplished-courses">
                <div className="container">
                    <h2 className="section-title pb-4">Accomplished Courses</h2>
                    {
                        courses.map(course => <Course
                            key={course.id}
                            course={course}
                        ></Course>)
                    }
                </div>


                {/* <div className="course-timeline">
                    <div className="course-timeline-item">
                        <div className="course-timeline-icon">
                            <span className="course-badge"><i className="fas fa-check-circle"></i></span>
                        </div>
                        <div className="course-timeline-content">
                            <div className="">
                                <div className="">
                                    <div className="">
                                        <h6 className="">June 23, 2021</h6>
                                        <h3 className="">AI For Everyone</h3>
                                        <p>DeepLearning.AI (Coursera)</p>
                                        <p>Grade Achieved: 89.37%</p>
                                        <a href="https://coursera.org/share/8c76ae644a134582d4b1f3b3e5efadc7">View Certificate</a>                                        
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>                   
                </div> */}
            </div>
        </div>
    );
};

export default Courses;
import React from 'react';
import './ProjectCard.css';

const ProjectCard = (props) => {
    const { title, description, thumbnail, technologies, link } = props.project;
    const { handleProjectOnClick } = props;

    return (
        <div onClick={() => handleProjectOnClick(link)} className="ProjectCard col">
            
                <div className="card h-100">
                    <img className="img-fluid card-img-top p-2" src={thumbnail} alt="" />
                    <div className="card-body">
                        <h5>{title}</h5>
                        <p className="mb-2">{description}</p>
                        <div className="technology">
                            {
                                technologies?.map(technology => <p
                                    key={technology.name}
                                    className={technology.className}
                                >{technology.name}</p>)
                            }
                        </div>
                    </div>
                </div>
            </div>
     
    );
};

export default ProjectCard;
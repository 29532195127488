import React, { useEffect, useState } from 'react';
import './Contact.css';
import ContactImage from '../../Images/contact.jpg';

const Contact = () => {
    const [message, setMessage] = useState({});
    const [displayMessage, setDisplayMessage] = useState();
    const [displayMessageClass, setDisplayMessageClass] = useState();

    useEffect(() => {
        const messageObj = {
            name: "",
            email: "",
            message: ""
        };
        setMessage(messageObj);
    }, []);


    const handleFormChange = (event) => {
        const messageObj = message;
        messageObj[event.target.name] = event.target.value;
        setMessage(messageObj);
    }

    const handleSendMessage = (event) => {

        const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailValid = emailReg.test(String(message.email).toLowerCase());


        if (message.name === "") {
            const message = "Please enter your name and send it again";
            const messageClass = "text-danger";
            setDisplayMessage(message);
            setDisplayMessageClass(messageClass);
        }
        else if (message.email === "") {
            const message = "Please enter your email and send it again";
            const messageClass = "text-danger";
            setDisplayMessage(message);
            setDisplayMessageClass(messageClass);
        }
        else if (message.message === "") {
            const message = "Please write your message and send it again";
            const messageClass = "text-danger";
            setDisplayMessage(message);
            setDisplayMessageClass(messageClass);
        }
        else if (!isEmailValid) {
            const message = "Please enter a valid email address";
            const messageClass = "text-danger";
            setDisplayMessage(message);
            setDisplayMessageClass(messageClass);
        }
        else {

            const url = `https://musaberul.com/backend/sendMessage.php`
            fetch(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(message)
            })
                .then(res => res.json())
                .then(data => {
                    if (data.isInserted === true) {
                        const message = "Your message was sent successfully. Thank you";
                        const messageClass = "text-success";
                        setDisplayMessage(message);
                        setDisplayMessageClass(messageClass);
                    }
                    else if (data.isInserted === false) {
                        const message = "Your message was not sent successfully. Please try again";
                        const messageClass = "text-danger";
                        setDisplayMessage(message);
                        setDisplayMessageClass(messageClass);
                    }
                })
        }

    }

    return (
        <div className="Contact container pt-5 mb-5">
            <h2 className="section-title">CONTACT</h2>
            <div className="row d-flex align-items-center">
                <div className="col-md-6 col-12 px-4">
                    <div>
                        <h3>Leave me a message</h3>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Your Name/Company</label>
                            <input name="name" onChange={(event) => handleFormChange(event)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Your Email</label>
                            <input name="email" onChange={(event) => handleFormChange(event)} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Message</label>
                            <textarea name="message" onChange={(event) => handleFormChange(event)} className="form-control" id="exampleFormControlTextarea1" rows="4" required></textarea>
                        </div>
                        <p className={displayMessageClass}>{displayMessage}</p>
                        <button onClick={(event) => handleSendMessage(event)} className="btn send-message-button px-4"><i className="fas fa-paper-plane me-2"></i>Send Message</button>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <img className="img-fluid" src={ContactImage} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Contact;
import React, { useEffect } from 'react';
import './About.css';
import AboutImage from '../../Images/about.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000
        });

    }, [])
    return (
        <div className="about container">
            <div className="section-title">
                <h2>ABOUT ME</h2>
            </div>
            <div className="row my-5">
                <div data-aos="fade-right" className="col-md-6 col-12 p-md-5 p-3">
                    <img className="img-fluid" src={AboutImage} alt="" />
                </div>
                <div className="col-md-6 col-12 about-me-text">
                    <div data-aos="flip-right" className="about-me-text-container">
                        <p>
                            Passionate IT professional with a capability for problem-solving and quality project delivery.
                        </p>
                        <p>
                            Currently, I am working as a Software Engineer in one of the largest software development companies in Bangladesh, utilizing my skills to create user-friendly interfaces for various projects.
                            I am proud to be part of a team that creates innovative solutions and contributes to the advancement of technology.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
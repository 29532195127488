import React, { useEffect } from 'react';
import './Header.css';
import musaberul from '../../Images/musaberul.png';
import Typewriter from "typewriter-effect";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    duration: 2000
});

const Header = () => {


    return (
        <div className='header-wrapper'>
            <div className="container-fluid header">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-6 col-12 d-flex justify-content-center align-items-center pb-5">
                            <div data-aos="fade-up" >
                                <h2>Ahmed Musaberul Khan</h2>
                                <h5 className="sub-title">
                                    <Typewriter
                                        onInit={(typewriter) => {
                                            typewriter
                                                .typeString("Front-End Web Developer")
                                                .pauseFor(1000)
                                                .start();
                                        }}
                                    />
                                </h5>
                                <div className="mb-4">
                                    <Typewriter
                                        hideCursorAfterText={true}
                                        onInit={(typewriter) => {
                                            typewriter

                                                .pauseFor(2000)
                                                .typeString("Passionate to do something tremendous!")
                                                .start();
                                        }}
                                    /></div>
                                <a target="_blank" href="https://drive.google.com/file/d/1CKgfcQBXFv8I0yW2asOIb_3v6FA-zDKL/view?usp=sharing" className="btn btn-warning px-4 py-2 download-resume" rel="noopener noreferrer"><i className="fas fa-file-download me-2"></i>Download Resume</a>
                                <a href="https://www.linkedin.com/in/musaberul/" target="_blank" rel="noopener noreferrer" className='linkedin'>
                                    <div>
                                        <i className="fab fa-linkedin"></i>
                                        <h4 className="d-inline-block">LinkedIn</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 d-flex mt-5 mt-md-0 px-5 justify-content-center align-items-center">
                            <div data-aos="fade-left">
                                <img className="header-image img-fluid" src={musaberul} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <svg viewBox="-2.93 488.5 604.753 113.677" preserveAspectRatio="none"><path d=" M 0 560 Q 118.192 471.782 310 560 Q 495.161 645.161 600 520 L 600 600 L 0 600 L 0 560 Z "></path></svg>

        </div>

    );
};

export default Header;
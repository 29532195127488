import React from 'react';
import About from '../About/About';
import Blog from '../Blog/Blog';
import Contact from '../Contact/Contact';
import Courses from '../Courses/Courses';
import Header from '../Header/Header';
import Projects from '../Projects/Projects';
import Skills from '../Skills/Skills';
import ExperienceEducation from '../ExperienceEducation/ExperienceEducation';


const Home = () => {
    return (
        <div>
            <Header></Header>
            <About></About>
            {/* <ExperienceEducation></ExperienceEducation> */}
            <Skills></Skills>
            <Projects></Projects>
            <Courses></Courses>
            <Contact></Contact>
            {/* <Blog></Blog> */}
        </div>
    );
};

export default Home;
import React, { useEffect, useState } from 'react';
import './ProjectDetails.css';
import { useParams } from 'react-router';

const ProjectDetails = () => {
    const [project, setProject] = useState({});
    let { link } = useParams();

    useEffect(() => {
        fetch('../projects.json')
            .then(res => res.json())
            .then(data => {
                const thisProject = data.find(pro => pro.link === link);
                setProject(thisProject);
                window.scrollTo(0, 0);
            });
    }, []);

    // console.log(project?.projectDetails?.background);

    return (
        <div className="project-details"
            style={{
                backgroundImage: `url(${project?.projectDetails?.background})`
            }}
        >
            <div className="container">
                <div className="d-flex justify-content-center">
                    {/* -------------- Project Title  ------------------ */}
                    <div className="project-page-title">
                        <h1 className="px-5 py-2 mb-0">{project?.title}</h1>
                    </div>
                </div>

                <div className="project-details-body px-4 py-5 mb-5">
                    {/* -------------- Project Description  ------------------ */}
                    <h4>Project Description:</h4><hr />
                    <p className="justified-p">
                        {project?.projectDetails?.projectDescription}
                    </p>


                    {/* ----------------- Technical Specifications ------------------ */}
                    <h4 className="mt-5">Technical Specifications:</h4><hr />
                    <ul>
                        {
                            project?.projectDetails?.technicalSpecifications.map((spec, index) => <li
                                key={index}
                            >
                                {spec}
                            </li>)
                        }
                    </ul>

                    {/* ----------------- Live Website Link ------------------ */}
                    <h4 className="mt-5">Live Website Link:</h4><hr />
                    <a href={project?.projectDetails?.live} className="btn btn-dark px-4 py-2" target="_blank" rel="noopener noreferrer">{project?.projectDetails?.liveText}</a>


                    {/* ----------------- Website Preview ------------------ */}
                    <h4 className="mt-5">Website Preview:</h4><hr />
                    {
                        project?.projectDetails?.preview.map(prev => <div
                            className="mb-5"
                            key={prev.text}
                        >
                            <h5 className="mb-3">{prev?.text}</h5>
                            <img src={prev?.image} className="img-fluid" alt="" />
                        </div>)
                    }

                </div>
            </div>
        </div>
    );
};

export default ProjectDetails;
import React from 'react';
import './Course.css';

const Course = (props) => {
    const { date, name, provider, grade, certificate } = props.course;

    return (
        <div className="course-timeline">
            <div className="course-timeline-item">
                <div className="course-timeline-icon">
                    <span className="course-badge"><i className="fas fa-check-circle"></i></span>
                </div>
                <div className="course-timeline-content">
                    <div className="">
                        <div className="">
                            <div className="">
                                <h6 className="course-date">{date}</h6>
                                <h3 className="course-heading">{name}</h3>
                                <p className="mb-0 course-text">{provider}</p>
                                <p className="mb-0 course-text">{grade}</p>
                                {/* <a href={certificate}  target="_blank">View Certificate</a> */}
                                <a href={certificate} className="d-block mb-4 view-certificate course-link" target="_blank" rel="noopener noreferrer">View Certificate</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Course;
import React from 'react';
import './Skills.css';
import Android from '../../Images/android.png';
import Bootstrap from '../../Images/bootstrap.png';
import CSS3 from '../../Images/css3.png';
import Git from '../../Images/git.png';
import HTML5 from '../../Images/html5.png';
import JavaScript from '../../Images/javascript.png';
import MySQL from '../../Images/mysql.png';
import PHP from '../../Images/php.png';
import ReactImg from '../../Images/react.png';
import SASS from '../../Images/sass.png';
import SqLite from '../../Images/sqlite.png';
import Express from '../../Images/express.png';
import MongoDB from '../../Images/mongodb.png';
import Laravel from '../../Images/laravel.png';
import NextJS from '../../Images/nextjs.png';
import Redux from '../../Images/redux.png';
import Gulp from '../../Images/gulp.png';
import Storybook from '../../Images/storybook.png';
import Moodle from '../../Images/moodle.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init({
    duration:2000
});

const Skills = () => {
    return (
        <div className="Skills container my-5">
            <h2 className="section-title">SKILLS</h2>
            <div data-aos="fade-up" className="row justify-content-center">
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={HTML5} alt="" /></div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={CSS3} alt="" /></div>                
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Bootstrap} alt="" /></div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={JavaScript} alt="" /></div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={SASS} alt="" /></div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={ReactImg} alt="" /></div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Redux} alt="" /></div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={NextJS} alt="" /></div>  
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Gulp} alt="" /></div>  
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Storybook} alt="" /></div>  
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Moodle} alt="" /></div>  
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Git} alt="" /></div>
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={PHP} alt="" /></div>   
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Laravel} alt="" /></div>   
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={MySQL} alt="" /></div>                 
                {/* <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Android} alt="" /></div>  */}
                {/* <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={SqLite} alt="" /></div>                  */}
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={Express} alt="" /></div>     
                <div className="col-lg-2 col-md-3 col-sm-4 col-6"><img className="img-fluid" src={MongoDB} alt="" /></div>
              
                  
            </div>

        </div>
    );
};

export default Skills;
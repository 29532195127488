import React, { useEffect, useState } from 'react';
import { useHistory  } from 'react-router-dom';
import './Projects.css';
import ProjectCard from './ProjectCard/ProjectCard';
import './Projects.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init({
    duration:2000
});


const Projects = () => {

    const [projects, setProjects] = useState([]);
    const [displayProjects, setDisplayProjects] = useState([]);
    let history = useHistory();


    useEffect(() => {
        fetch('projects.json')
            .then(res => res.json())
            .then(data => {
                setProjects(data);
                setDisplayProjects(data);
            }
            )
    }, []);
 
    const changeDisplayProjects = (category) => {
        const newDisplayProjects = projects.filter(project =>  project.type === category);
        setDisplayProjects(newDisplayProjects);
    }

    const handleProjectOnClick = (link) => {
        history.push(`/project/${link}`);
    }

    return (
        <div className="Projects container mb-5">
            <h2 className="section-title pt-5">PROJECTS</h2>
            <div data-aos="fade-down" className="projects-filter">
                <button onClick={() => setDisplayProjects(projects)} className="btn px-3 py-1 m-1">All</button>
                <button onClick={() => changeDisplayProjects('dynamic')} className="btn px-3 py-1 m-1">Dynamic Website</button>
                <button onClick={() => changeDisplayProjects('static')} className="btn px-3 py-1 m-1">Static Website</button>
                <button onClick={() => changeDisplayProjects('webapp')} className="btn px-3 py-1 m-1">Web App</button>
                {/* <button onClick={() => changeDisplayProjects('android')} className="btn px-3 py-1 m-1">Android App</button> */}
                <button onClick={() => changeDisplayProjects('desktop')} className="btn px-3 py-1 m-1">Desktop App</button>
            </div>
            <div data-aos="zoom-in" className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 projects-display-cards">
                {
                    displayProjects?.map(project => <ProjectCard
                        key={project.id}
                        project={project}
                        handleProjectOnClick={handleProjectOnClick}
                    ></ProjectCard>)
                }
            </div>
        </div>
    );
};

export default Projects;
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from '../Home/Home';
import About from '../About/About';
import './Navigation.css';
import Skills from '../Skills/Skills';
import Projects from '../Projects/Projects';
import Contact from '../Contact/Contact';
import ProjectDetails from '../Projects/ProjectDetails/ProjectDetails';

const Navigation = () => {
    return (
        <Router>
            {/* --------------------------------------------------------------------------------
                                        Bootstrap Navbar
            -------------------------------------------------------------------------------- */}
            <div className="Navigation">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand fs-2 px-2" to="/">Musaberul's <span>Portfolio</span> </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <Link className="nav-link" aria-current="page" to="/"><i className="fas fa-home"></i>Home</Link>
                                <Link className="nav-link" to="/about"><i className="fas fa-user-tie"></i>About</Link>
                                <Link className="nav-link" to="/skills"><i className="fas fa-tasks"></i>Skills</Link>
                                <Link className="nav-link" to="/projects"><i className="fas fa-briefcase"></i>Projects</Link>
                                <Link className="nav-link" to="/contact"><i className="fas fa-at"></i>Contact</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>


            {/* --------------------------------------------------------------------------------
                                                Router Switch
            -------------------------------------------------------------------------------- */}
            <Switch>
                <Route exact path="/">
                    <Home></Home>
                </Route>
                <Route path="/about">
                    <About></About>
                </Route>
                <Route path="/skills">
                    <Skills></Skills>
                </Route>
                <Route path="/projects">
                    <Projects></Projects>
                </Route>
                <Route path="/contact">
                    <Contact></Contact>
                </Route>
                <Route path="/project/:link">
                    <ProjectDetails></ProjectDetails>
                </Route>               
            </Switch>
        </Router>

    );
};

export default Navigation;